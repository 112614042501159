/**
 * Buscar Todos os Dominios
 */
export function getAllDomainsRequest() {
  return {
    type: '@allDomains/GET_ALL_DOMAINS_REQUEST',
    payload: {},
  };
}

export function getAllDomainsSuccess(domains) {
  return {
    type: '@allDomains/GET_ALL_DOMAINS_SUCCESS',
    payload: { domains },
  };
}

export function getAllDomainsFailure() {
  return {
    type: '@allDomains/GET_ALL_DOMAINS_FAILURE',
  };
}

/**
 * Buscar Todos os Usuários de um domínio
 */
export function getAllUserFromDomainsRequest(id) {
  return {
    type: '@allDomains/GET_ALL_USERS_FROM_DOMAINS_REQUEST',
    payload: { id },
  };
}

export function getAllUserFromDomainsSuccess(users) {
  return {
    type: '@allDomains/GET_ALL_USERS_FROM_DOMAINS_SUCCESS',
    payload: { users },
  };
}

export function getAllUserFromDomainsFailure() {
  return {
    type: '@allDomains/GET_ALL_USERS_FROM_DOMAINS_FAILURE',
  };
}

export function clearAllUserFromDomainsRequest() {
  return {
    type: '@allDomains/CLEAR_ALL_USERS_FROM_DOMAINS_REQUEST',
  };
}

/**
 * Busca os dados de um usuário
 */
export function getUserDataRequest({ userId, domainId }) {
  return {
    type: '@allDomains/GET_USER_DATA_REQUEST',
    payload: { userId, domainId },
  };
}

export function getUserDataSuccess(user) {
  return {
    type: '@allDomains/GET_USER_DATA_SUCCESS',
    payload: { user },
  };
}

export function getUserDataFailure() {
  return {
    type: '@allDomains/GET_USER_DATA_FAILURE',
  };
}

/**
 * Altera os dados de um usuário
 */
export function updateUserDataRequest({ data }) {
  return {
    type: '@allDomains/UPDATE_USER_DATA_REQUEST',
    payload: { data },
  };
}

export function updateUserDataSuccess(user) {
  return {
    type: '@allDomains/UPDATE_USER_DATA_SUCCESS',
    payload: { user },
  };
}

export function updateUserDataFailure() {
  return {
    type: '@allDomains/UPDATE_USER_DATA_FAILURE',
  };
}
