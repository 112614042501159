import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';

// import Webphone from '~/components/WebphoneSIP';
// import Webphone from '~/components/WebphoneCloud';
import Webphone from '~/components/WebphoneFront';
import { ListContainer } from './styles';

export default function WebPhonePage() {
  // const profile = useSelector(state => state.user.profile);

  const [loading] = useState(false);

  return (
    <ListContainer>
      {loading && (
        <Segment color="blue" className="div-tamanho">
          <Dimmer active inverted>
            <Loader inverted content="Carregando" />
          </Dimmer>
        </Segment>
      )}

      {!loading && (
        <Segment color="blue" className="div-tamanho div-centralizar">
          {/* <p>{JSON.stringify(profile)}</p> */}
          <Webphone />
        </Segment>
      )}
    </ListContainer>
  );
}
