import React, { useState, useEffect } from 'react';
import {
  Segment,
  Form,
  Dimmer,
  Loader,
  Button,
  Icon,
  Table,
  Modal,
  Message,
  Checkbox,
} from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  getAllDomainsRequest,
  getAllUserFromDomainsRequest,
  clearAllUserFromDomainsRequest,
  getUserDataRequest,
  updateUserDataRequest,
} from '~/store/modules/dominios/actions';

import api from '~/services/api';
import { ListContainer, ContainerButton, ContainerForm } from './styles';

const options = [
  { key: 1, value: 1, text: 'Comum' },
  { key: 2, value: 2, text: 'Admin' },
  { key: 3, value: 3, text: 'Super User' },
];

const optionsLogin = [
  { key: 1, value: 1, text: 'Ativar' },
  { key: 2, value: 2, text: 'Desativar' },
];

const optionsGravacao = [
  { key: 1, value: 1, text: 'Ativar' },
  { key: 2, value: 2, text: 'Desativar' },
];

export default function Dominios() {
  const [ativo, setAtivo] = useState(false);
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [user_basix, setUserBasix] = useState('');
  const [callCenterGroup, setCallCenterGroup] = useState('');
  const [senha, setSenha] = useState('');
  const [tipo, setTipo] = useState();
  const [loginlogout, setLoginlogout] = useState();
  const [gravacao, setGravacao] = useState();
  const [mostrarHistorico, setMostrarHistorico] = useState();
  const [descricao, setDescricao] = useState('');
  const [ativo_dendron, setAtivoDendron] = useState(false);
  const [dendron_operador, setDendronOperador] = useState('');
  const [dendron_token, setDendronToken] = useState('');
  const [ativo_zendesk, setAtivoZendesk] = useState(false);
  const [email_zendesk, setEmailZendesk] = useState('');
  const [token_zendesk, setTokenZendesk] = useState('');
  const [sub_dominio_zendesk, setSubDominioZendesk] = useState('');
  const [loadingForm, setLoadingFrom] = useState(true);

  const [currentUserId, setCurrentUserId] = useState(null);
  const [currentDomainId, setCurrentDomainId] = useState(null);

  const [open, setOpen] = useState(false);
  const [openModalDomain, setOpenModalDomain] = useState(false);
  const [openModalRemoveDomain, setOpenModalRemoveDomain] = useState(false);
  const [openModalAdicionarUsuario, setOpenModalAdicionarUsuario] = useState(
    false
  );
  const [addDomain, setAddDomain] = useState('');
  const {
    domains,
    loading,
    users,
    loadingUsers,
    user,
    loadingUserData,
  } = useSelector(state => state.dominios);
  const [selectDomain, setSelectDomain] = useState(null);
  const [optionsDomains, setOptionsDomains] = useState([
    { key: null, value: null, text: 'Selecione um domínio' },
  ]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (domains && domains.length > 0) {
      setOptionsDomains([
        { key: null, value: null, text: 'Selecione um domínio' },
        ...domains.map(item => {
          return {
            key: item.dominio,
            value: item.id,
            text: item.dominio,
          };
        }),
      ]);
    }
  }, [domains]);

  useEffect(() => {
    dispatch(getAllDomainsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (selectDomain) {
      dispatch(getAllUserFromDomainsRequest(selectDomain));
    }
  }, [dispatch, selectDomain]);

  useEffect(() => {
    return () => {
      dispatch(clearAllUserFromDomainsRequest());
    };
  }, [dispatch]);

  useEffect(() => {
    if (user && user.id) {
      setAtivo(user.ativo === 1);
      setNome(user.nome || '');
      setEmail(user.email || '');
      setUserBasix(user.user_basix || '');
      setCallCenterGroup(user.callcenter_group || '');
      setTipo(user.tipo);
      setLoginlogout(user.loginlogout);
      setGravacao(user.gravacao);
      setMostrarHistorico(user.historico);
      setDescricao(user.descricao || '');
      setAtivoDendron(user.ativo_dendron === 1);
      setDendronOperador(user.dendron_operador || '');
      setDendronToken(user.dendron_token || '');
      setAtivoZendesk(user.ativo_zendesk === 1);
      setEmailZendesk(user.email_zendesk || '');
      setTokenZendesk(user.token_zendesk || '');
      setSubDominioZendesk(user.sub_dominio_zendesk || '');
      setLoadingFrom(false);

      setCurrentUserId(user.id);
      setCurrentDomainId(user.fk_id_dominio);
    }
  }, [user]);

  const handleSubmitAddDomain = async () => {
    try {
      await api.post('/domains', { dominio: addDomain });
      dispatch(getAllDomainsRequest());
      toast.success('Domínio adicionado com sucesso');
      setAddDomain('');
    } catch (error) {
      toast.error('Falha ao adicionar dominio');
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        toast.error(error.response.data.error);
      }
    }
  };

  const handleDeleteDomain = async () => {
    try {
      await api.delete(`/domains/${selectDomain}`);
      dispatch(getAllDomainsRequest());
      setSelectDomain(null);
      toast.success('Domínio removido com sucesso');
    } catch (error) {
      toast.error('Falha ao remover domínio');
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        toast.error(error.response.data.error);
      }
    }
  };

  const handleAddUser = async () => {
    try {
      await api.post(`/users/${selectDomain}`, {
        ativo,
        nome,
        email,
        senha,
        callcenter_group: callCenterGroup,
        user_basix,
        tipo,
        loginlogout,
        gravacao,
        descricao,
        historico: mostrarHistorico,
        ativo_dendron,
        dendron_operador,
        dendron_token,
        ativo_zendesk,
        email_zendesk,
        token_zendesk,
        sub_dominio_zendesk,
      });

      toast.success('Usuário adicionado');
      dispatch(getAllUserFromDomainsRequest(selectDomain));
    } catch (error) {
      toast.error('Falha adicionar usuário');
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        toast.error(error.response.data.error);
      }
    }
  };

  const handleSubmit = () => {
    dispatch(
      updateUserDataRequest({
        data: {
          ativo,
          nome,
          email,
          user_basix,
          callCenterGroup,
          senha,
          tipo,
          loginlogout,
          gravacao,
          mostrarHistorico,
          descricao,
          ativo_dendron,
          dendron_operador,
          dendron_token,
          ativo_zendesk,
          email_zendesk,
          token_zendesk,
          sub_dominio_zendesk,
          currentUserId,
          currentDomainId,
        },
      })
    );
  };

  return (
    <ListContainer>
      {loading && (
        <Segment className="div-carregando">
          <Dimmer active inverted>
            <Loader inverted content="Carregando" />
          </Dimmer>
        </Segment>
      )}
      <Segment color="blue" className="div-tamanho">
        {!loading && (
          <>
            <ContainerButton>
              <Form>
                <Form.Group inline>
                  <Form.Select
                    style={{ width: '250px' }}
                    fluid
                    name="loginlogout"
                    label="Domínio"
                    options={optionsDomains}
                    placeholder="Selecione um domínio"
                    value={selectDomain}
                    onChange={(e, v) => setSelectDomain(v.value)}
                  />
                </Form.Group>
              </Form>
              <Button
                icon
                labelPosition="left"
                primary
                size="small"
                onClick={() => {
                  setOpenModalDomain(true);
                }}
              >
                <Icon name="building outline" /> Adicionar Domínio
              </Button>
              {selectDomain && (
                <Button
                  icon
                  labelPosition="left"
                  primary
                  size="small"
                  onClick={() => {
                    setOpenModalAdicionarUsuario(true);

                    setLoadingFrom(true);

                    // Limpar Campos
                    setAtivo(true);
                    setNome('');
                    setEmail('');
                    setUserBasix('');
                    setCallCenterGroup('');
                    setTipo(1);
                    setLoginlogout(1);
                    setGravacao(2);
                    setMostrarHistorico(1);
                    setDescricao('');
                    setAtivoDendron(0);
                    setDendronOperador('');
                    setDendronToken('');
                    setAtivoZendesk(0);
                    setEmailZendesk('');
                    setTokenZendesk('');
                    setSubDominioZendesk('');

                    setLoadingFrom(false);
                  }}
                >
                  <Icon name="user" /> Adicionar Usuário
                </Button>
              )}
              {selectDomain && (
                <Button
                  icon
                  color="red"
                  labelPosition="left"
                  size="small"
                  onClick={() => {
                    setOpenModalRemoveDomain(true);
                  }}
                >
                  <Icon name="building outline" /> Remover Domínio
                </Button>
              )}
            </ContainerButton>

            {loadingUsers && (
              <Segment className="div-carregando">
                <Dimmer active inverted>
                  <Loader inverted content="Carregando" />
                </Dimmer>
              </Segment>
            )}
            {!loadingUsers && (
              <Table celled color="blue">
                <Table.Header>
                  <Table.Row textAlign="center">
                    <Table.HeaderCell>ID</Table.HeaderCell>
                    <Table.HeaderCell>Ativo</Table.HeaderCell>
                    <Table.HeaderCell>Nome</Table.HeaderCell>
                    <Table.HeaderCell>Usuário</Table.HeaderCell>
                    <Table.HeaderCell>Ativo Dendron</Table.HeaderCell>
                    <Table.HeaderCell>Ativo Zendesk</Table.HeaderCell>
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {users.map(usuario => {
                    return (
                      <Table.Row key={usuario.id} className="btn-actions">
                        <Table.Cell textAlign="center">{usuario.id}</Table.Cell>
                        <Table.Cell
                          positive={usuario.ativo === 1}
                          negative={!usuario.ativo}
                          textAlign="center"
                        >
                          {!usuario.ativo && <Icon name="close" />}
                          {!!usuario.ativo && <Icon name="checkmark" />}
                          {usuario.ativo ? 'Ativo' : 'Inativo'}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {usuario.nome}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {usuario.user_basix}
                        </Table.Cell>
                        <Table.Cell
                          positive={usuario.ativo_dendron === 1}
                          negative={!usuario.ativo_dendron}
                          textAlign="center"
                        >
                          {!usuario.ativo_dendron && <Icon name="close" />}
                          {!!usuario.ativo_dendron && <Icon name="checkmark" />}
                          {usuario.ativo_dendron ? 'Ativo' : 'Inativo'}
                        </Table.Cell>
                        <Table.Cell
                          positive={usuario.ativo_zendesk === 1}
                          negative={!usuario.ativo_zendesk}
                          textAlign="center"
                        >
                          {!usuario.ativo_zendesk && <Icon name="close" />}
                          {!!usuario.ativo_zendesk && <Icon name="checkmark" />}
                          {usuario.ativo_zendesk ? 'Ativo' : 'Inativo'}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {/* <Link to={`/usuarios/${usuario.id}`}>
                          <Button size="mini" color="yellow">
                            <Icon name="edit" /> Editar
                          </Button>
                        </Link> */}
                          <Button
                            color="yellow"
                            size="mini"
                            onClick={() => {
                              dispatch(
                                getUserDataRequest({
                                  userId: usuario.id,
                                  domainId: selectDomain,
                                })
                              );
                              setLoadingFrom(true);
                              setOpen(true);
                            }}
                          >
                            Editar
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            )}
          </>
        )}
      </Segment>

      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Alterar Usuário</Modal.Header>
        <Modal.Content>
          <ContainerForm>
            {loadingForm && (
              <Segment className="div-carregando">
                <Dimmer active inverted>
                  <Loader inverted content="Carregando" />
                </Dimmer>
              </Segment>
            )}
            {!loadingForm && (
              <Form loading={loadingUserData} onSubmit={handleSubmit}>
                <Message>
                  <Form.Field>
                    <Checkbox
                      name="ativo"
                      label="Ativo"
                      defaultChecked={ativo}
                      onChange={() => setAtivo(!ativo)}
                    />
                  </Form.Field>

                  <Form.Group widths="equal">
                    <Form.Input
                      name="nome"
                      fluid
                      label="Nome"
                      placeholder="Nome do usuário"
                      value={nome}
                      onChange={e => setNome(e.target.value)}
                    />
                    <Form.Input
                      name="email"
                      fluid
                      label="E-mail"
                      placeholder="E-mail usado para login"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                    <Form.Input
                      name="user_basix"
                      fluid
                      label="Usuário Basix"
                      placeholder="Usuário do PBX Basix"
                      value={user_basix}
                      onChange={e => setUserBasix(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Form.Input
                      name="senha"
                      fluid
                      label="Senha"
                      placeholder="Senha para efetuar login"
                      type="password"
                      value={senha}
                      onChange={e => setSenha(e.target.value)}
                    />
                    <Form.Input
                      name="callcenter_group"
                      fluid
                      label="callcenter_group"
                      placeholder="Grupo CallCenter para buscar pausas"
                      type="text"
                      value={callCenterGroup}
                      onChange={e => setCallCenterGroup(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Form.Select
                      fluid
                      name="tipo"
                      label="Tipo de usuário"
                      options={options}
                      placeholder="Selecione uma opção"
                      value={tipo}
                      onChange={(e, v) => setTipo(v.value)}
                    />

                    <Form.Select
                      fluid
                      name="loginlogout"
                      label="Login e Logout via Web"
                      options={optionsLogin}
                      placeholder="Selecione uma opção"
                      value={loginlogout}
                      onChange={(e, v) => setLoginlogout(v.value)}
                    />

                    <Form.Select
                      fluid
                      name="gravacao"
                      label="Buscar gravação da chamada no log"
                      options={optionsGravacao}
                      placeholder="Selecione uma opção"
                      value={gravacao}
                      onChange={(e, v) => setGravacao(v.value)}
                    />
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Form.Select
                      fluid
                      name="historico"
                      label="Mostrar historico do chamado"
                      options={optionsGravacao}
                      placeholder="Selecione uma opção"
                      value={mostrarHistorico}
                      onChange={(e, v) => setMostrarHistorico(v.value)}
                    />
                  </Form.Group>

                  <Form.TextArea
                    name="descricao"
                    label="Detalhes adicionais"
                    placeholder="descrição..."
                    value={descricao}
                    onChange={e => setDescricao(e.target.value)}
                  />

                  <Message color="green">
                    <Checkbox
                      className="check-integracao"
                      name="ativo_dendron"
                      label="Integração com a Dendron"
                      defaultChecked={ativo_dendron}
                      onChange={() => setAtivoDendron(!ativo_dendron)}
                    />
                    <Form.Group widths="equal">
                      <Form.Input
                        name="dendron_operador"
                        fluid
                        label="Operador Dendron"
                        placeholder="Senha para efetuar login"
                        type="text"
                        value={dendron_operador}
                        onChange={e => setDendronOperador(e.target.value)}
                      />

                      <Form.Input
                        name="dendron_token"
                        fluid
                        label="Token Dendron"
                        placeholder="Senha para efetuar login"
                        type="text"
                        value={dendron_token}
                        onChange={e => setDendronToken(e.target.value)}
                      />
                    </Form.Group>
                  </Message>

                  <Message color="green">
                    <Checkbox
                      name="ativo_zendesk"
                      className="check-integracao"
                      label="Integração com a Zendesk"
                      defaultChecked={ativo_zendesk}
                      onChange={() => setAtivoZendesk(!ativo_zendesk)}
                    />
                    <Form.Group widths="equal">
                      <Form.Input
                        name="email_zendesk"
                        fluid
                        label="Email Zendesk"
                        placeholder="Senha para efetuar login"
                        type="text"
                        value={email_zendesk}
                        onChange={e => setEmailZendesk(e.target.value)}
                      />

                      <Form.Input
                        name="token_zendesk"
                        fluid
                        label="Zendesk Token"
                        placeholder="Senha para efetuar login"
                        type="text"
                        value={token_zendesk}
                        onChange={e => setTokenZendesk(e.target.value)}
                      />

                      <Form.Input
                        name="sub_dominio_zendesk"
                        fluid
                        label="Sub Dominio"
                        placeholder="Senha para efetuar login"
                        type="text"
                        value={sub_dominio_zendesk}
                        onChange={e => setSubDominioZendesk(e.target.value)}
                      />
                    </Form.Group>
                  </Message>

                  {/* <Button primary fluid type="submit">
                    Alterar
                  </Button> */}
                </Message>
              </Form>
            )}
          </ContainerForm>
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={() => setOpen(false)}>
            Fechar
          </Button>
          <Button
            content="Alterar"
            labelPosition="right"
            icon="checkmark"
            onClick={() => {
              setOpen(false);
              handleSubmit();
            }}
            positive
          />
        </Modal.Actions>
      </Modal>

      <Modal
        onClose={() => setOpenModalDomain(false)}
        onOpen={() => setOpenModalDomain(true)}
        open={openModalDomain}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Adicionar Domínio</Modal.Header>
        <Modal.Content>
          <Form>
            {/* <Message> */}
            <Form.Group widths="equal">
              <Form.Input
                name="dominio"
                fluid
                label="Domínio"
                placeholder="Domínio"
                value={addDomain}
                onChange={e => setAddDomain(e.target.value)}
              />
            </Form.Group>
            {/* </Message> */}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={() => setOpenModalDomain(false)}>
            Fechar
          </Button>
          <Button
            content="Adicionar"
            labelPosition="right"
            icon="plus"
            onClick={() => {
              setOpenModalDomain(false);
              handleSubmitAddDomain();
            }}
            positive
          />
        </Modal.Actions>
      </Modal>

      <Modal
        onClose={() => setOpenModalRemoveDomain(false)}
        onOpen={() => setOpenModalRemoveDomain(true)}
        open={openModalRemoveDomain}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Remover Domínio</Modal.Header>
        <Modal.Content>
          {`Remover o dominio ${domains.length > 0 && selectDomain
              ? domains.find(item => item.id === selectDomain).dominio || ''
              : ''
            } ?`}
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={() => setOpenModalRemoveDomain(false)}>
            Não
          </Button>
          <Button
            content="Remover"
            labelPosition="right"
            icon="delete"
            color="red"
            onClick={() => {
              setOpenModalRemoveDomain(false);
              handleDeleteDomain();
            }}
          />
        </Modal.Actions>
      </Modal>

      <Modal
        onClose={() => setOpenModalAdicionarUsuario(false)}
        onOpen={() => setOpenModalAdicionarUsuario(true)}
        open={openModalAdicionarUsuario}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Adicionar Usuário</Modal.Header>
        <Modal.Content>
          <ContainerForm>
            {loadingForm && (
              <Segment className="div-carregando">
                <Dimmer active inverted>
                  <Loader inverted content="Carregando" />
                </Dimmer>
              </Segment>
            )}
            {!loadingForm && (
              <Form loading={loadingUserData} onSubmit={handleSubmit}>
                <Message>
                  <Form.Field>
                    <Checkbox
                      name="ativo"
                      label="Ativo"
                      defaultChecked={ativo}
                      onChange={() => setAtivo(!ativo)}
                    />
                  </Form.Field>

                  <Form.Group widths="equal">
                    <Form.Input
                      name="nome"
                      fluid
                      label="Nome"
                      placeholder="Nome do usuário"
                      value={nome}
                      onChange={e => setNome(e.target.value)}
                    />
                    <Form.Input
                      name="email"
                      fluid
                      label="E-mail"
                      placeholder="E-mail usado para login"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                    <Form.Input
                      name="user_basix"
                      fluid
                      label="Usuário Basix"
                      placeholder="Usuário do PBX Basix"
                      value={user_basix}
                      onChange={e => setUserBasix(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Form.Input
                      name="senha"
                      fluid
                      label="Senha"
                      placeholder="Senha para efetuar login"
                      type="password"
                      value={senha}
                      onChange={e => setSenha(e.target.value)}
                    />
                    <Form.Input
                      name="callcenter_group"
                      fluid
                      label="callcenter_group"
                      placeholder="Grupo CallCenter para buscar pausas"
                      type="text"
                      value={callCenterGroup}
                      onChange={e => setCallCenterGroup(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Form.Select
                      fluid
                      name="tipo"
                      label="Tipo de usuário"
                      options={options}
                      placeholder="Selecione uma opção"
                      value={tipo}
                      onChange={(e, v) => setTipo(v.value)}
                    />

                    <Form.Select
                      fluid
                      name="loginlogout"
                      label="Login e Logout via Web"
                      options={optionsLogin}
                      placeholder="Selecione uma opção"
                      value={loginlogout}
                      onChange={(e, v) => setLoginlogout(v.value)}
                    />

                    <Form.Select
                      fluid
                      name="gravacao"
                      label="Buscar gravação da chamada no log"
                      options={optionsGravacao}
                      placeholder="Selecione uma opção"
                      value={gravacao}
                      onChange={(e, v) => setGravacao(v.value)}
                    />
                  </Form.Group>

                  <Form.Group widths="equal">
                    <Form.Select
                      fluid
                      name="historico"
                      label="Mostrar historico do chamado"
                      options={optionsGravacao}
                      placeholder="Selecione uma opção"
                      value={mostrarHistorico}
                      onChange={(e, v) => setMostrarHistorico(v.value)}
                    />
                  </Form.Group>

                  <Form.TextArea
                    name="descricao"
                    label="Detalhes adicionais"
                    placeholder="descrição..."
                    value={descricao}
                    onChange={e => setDescricao(e.target.value)}
                  />

                  <Message color="green">
                    <Checkbox
                      className="check-integracao"
                      name="ativo_dendron"
                      label="Integração com a Dendron"
                      defaultChecked={ativo_dendron}
                      onChange={() => setAtivoDendron(!ativo_dendron)}
                    />
                    <Form.Group widths="equal">
                      <Form.Input
                        name="dendron_operador"
                        fluid
                        label="Operador Dendron"
                        placeholder="Senha para efetuar login"
                        type="text"
                        value={dendron_operador}
                        onChange={e => setDendronOperador(e.target.value)}
                      />

                      <Form.Input
                        name="dendron_token"
                        fluid
                        label="Token Dendron"
                        placeholder="Senha para efetuar login"
                        type="text"
                        value={dendron_token}
                        onChange={e => setDendronToken(e.target.value)}
                      />
                    </Form.Group>
                  </Message>

                  <Message color="green">
                    <Checkbox
                      name="ativo_zendesk"
                      className="check-integracao"
                      label="Integração com a Zendesk"
                      defaultChecked={ativo_zendesk}
                      onChange={() => setAtivoZendesk(!ativo_zendesk)}
                    />
                    <Form.Group widths="equal">
                      <Form.Input
                        name="email_zendesk"
                        fluid
                        label="Email Zendesk"
                        placeholder="Senha para efetuar login"
                        type="text"
                        value={email_zendesk}
                        onChange={e => setEmailZendesk(e.target.value)}
                      />

                      <Form.Input
                        name="token_zendesk"
                        fluid
                        label="Zendesk Token"
                        placeholder="Senha para efetuar login"
                        type="text"
                        value={token_zendesk}
                        onChange={e => setTokenZendesk(e.target.value)}
                      />

                      <Form.Input
                        name="sub_dominio_zendesk"
                        fluid
                        label="Sub Dominio"
                        placeholder="Senha para efetuar login"
                        type="text"
                        value={sub_dominio_zendesk}
                        onChange={e => setSubDominioZendesk(e.target.value)}
                      />
                    </Form.Group>
                  </Message>

                  {/* <Button primary fluid type="submit">
                    Alterar
                  </Button> */}
                </Message>
              </Form>
            )}
          </ContainerForm>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            onClick={() => setOpenModalAdicionarUsuario(false)}
          >
            Fechar
          </Button>
          <Button
            content="Adicionar"
            labelPosition="right"
            icon="checkmark"
            onClick={() => {
              handleAddUser();
              setOpenModalAdicionarUsuario(false);
            }}
            positive
          />
        </Modal.Actions>
      </Modal>
    </ListContainer>
  );
}
