import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Segment,
  Table,
  Button,
  Icon,
  Modal,
  Form,
  Dimmer,
  Loader,
  Popup,
} from 'semantic-ui-react';

import api from '~/services/api';

import { ListContainer, ListButtons, ButtonSpace } from './styles';

export default function Mailings_V2() {
  const { id_dominio } = useSelector(state => state.user.profile);

  const [mailings, setMailings] = useState([]);
  const [loadingContatosMailing, setLoadingContatosMailing] = useState(false);

  const [modalAdicionarMailing, setModalAdicionarMailing] = useState(false);
  const [modalEditarMailing, setModalEditarMailing] = useState(false);
  const [modalRemoverMailing, setModalRemoverMailing] = useState(false);
  const [modalImportarContatos, setModalImportarContatos] = useState(false);
  const [modalExportarTabulacao, setModalExportarTabulacao] = useState(false);
  const [
    modalEnviarContatosPendentes,
    setModalEnviarContatosPendentes,
  ] = useState(false);

  const [nomeMailing, setNomeMailing] = useState('');
  const [token, setToken] = useState('');
  const [context, setContext] = useState('');
  const [file, setFile] = useState(null);

  const [idEditModal, setIdEditModal] = useState(null);
  const [idRemoveModal, setIdRemoveModal] = useState(null);
  const [idImportModal, setIdImportModal] = useState(null);
  const [idEnviarPendentesModal, setIdEnviarPendentesModal] = useState(null);

  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');

  const buscarMailings = async () => {
    try {
      setLoadingContatosMailing(true);
      const { data } = await api.get('/mailing', {
        params: { fk_id_dominio: id_dominio },
      });
      setMailings(data);
      setLoadingContatosMailing(false);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        toast.error(error.response.data.error);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleAdicionarMailing = async () => {
    try {
      const encontrado = mailings.find(
        item => item.nome_campanha === nomeMailing
      );

      if (encontrado) {
        toast.error('Mailing já foi adicionado');
        return;
      }

      if (nomeMailing && token && context) {
        const { data } = await api.post('/mailing', {
          fk_id_dominio: id_dominio,
          nome_campanha: nomeMailing,
          token,
          context,
        });

        toast.success(data.message);
        buscarMailings();
      } else {
        toast.warn('Todos os campos são obrigatórios');
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        toast.error(error.response.data.error);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleEditarMailing = async () => {
    try {
      const encontrado = mailings.find(
        item => item.nome_campanha === nomeMailing && item.id !== idEditModal
      );

      if (encontrado) {
        toast.error('Mailing já foi adicionado');
        return;
      }

      if (nomeMailing && token && context) {
        const { data } = await api.put(`/mailing/${idEditModal}`, {
          fk_id_dominio: id_dominio,
          nome_campanha: nomeMailing,
          token,
          context,
        });

        toast.success(data.message);
        buscarMailings();
      } else {
        toast.warn('Todos os campos são obrigatórios');
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        toast.error(error.response.data.error);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleRemoverMailing = async () => {
    try {
      if (idRemoveModal) {
        const { data } = await api.delete(`/mailing/${idRemoveModal}`);

        toast.success(data.message);
        setIdRemoveModal(null);
        buscarMailings();
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        toast.error(error.response.data.error);
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleImportarContatos = async () => {
    setLoadingContatosMailing(true);

    try {
      const formData = new FormData();
      // formData.append('campanha', nomeMailing);
      formData.append('fk_id_dominio', id_dominio);
      formData.append('fk_id_mailing', idImportModal);
      formData.append('file', file);

      await api.post('/mailing/contato', formData);
      toast.success('Upload efetuado');
      setModalImportarContatos(false);
      // buscarContatosMailing();
    } catch (error) {
      setLoadingContatosMailing(false);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        toast.error(error.response.data.error);
      } else {
        toast.error(error.message);
      }
    }

    setLoadingContatosMailing(false);
  };

  const handleDownloadTabulacoes = async () => {
    setLoadingContatosMailing(true);

    const FileDownload = require('js-file-download');

    const { data } = await api.get(`/discador/log/grupoalliar`, {
      params: {
        inicio: dataInicial,
        termino: dataFinal,
      },
    });

    FileDownload(data, 'tabulacoes.csv');
    setLoadingContatosMailing(false);
  };

  const handleDownloadRetorno = async () => {
    setLoadingContatosMailing(true);

    const FileDownload = require('js-file-download');

    const { data } = await api.post(`/mailing/export`, {
      fk_id_dominio: id_dominio,
    });

    FileDownload(data, 'retorno.csv');
    setLoadingContatosMailing(false);
  };

  const handleRemoverTodosContatos = async () => {
    setLoadingContatosMailing(true);

    // const { data } = await api.post('/discador/pausar', {
    //   ids: contatosMailing.map(item => item.id),
    //   fk_id_dominio: id_dominio,
    //   fk_id_mailing: selectMailing,
    // });
    // buscarContatosMailing();
    // toast.success(data.message);
    toast.warn(
      'O processo de pausar a campanha deve ser feito no Voice Banner'
    );

    setLoadingContatosMailing(false);
  };

  const handleEnviarContatosPendentes = async () => {
    setLoadingContatosMailing(true);

    await api.post('/discador/enviar/pendentes', {
      fk_id_dominio: id_dominio,
      fk_id_mailing: idEnviarPendentesModal,
    });
    toast.success('Contatos enviados para o Discador');
    setLoadingContatosMailing(false);
  };

  useEffect(() => {
    buscarMailings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ListContainer>
      {loadingContatosMailing && (
        <Segment color="blue" className="div-tamanho">
          <Dimmer active inverted>
            <Loader inverted content="Carregando" />
          </Dimmer>
        </Segment>
      )}

      {!loadingContatosMailing && (
        <Segment color="blue" className="div-tamanho">
          {/* {mailings && mailings.length > 0 && (
            <p>{JSON.stringify(mailings[0])}</p>
          )} */}

          <ListButtons>
            <Button
              icon
              labelPosition="left"
              color="green"
              size="small"
              onClick={() => {
                setNomeMailing('');
                setToken('');
                setContext('');
                setTimeout(() => {
                  setModalAdicionarMailing(true);
                }, 1);
              }}
            >
              <Icon name="plus circle" /> Adicionar Mailing
            </Button>

            <Button
              icon
              labelPosition="left"
              primary
              size="small"
              onClick={() => {
                buscarMailings();
              }}
            >
              <Icon name="refresh" /> Atualizar
            </Button>

            <ButtonSpace />

            <Popup
              content="Log de Tabulações"
              trigger={
                <Button
                  icon="list alternate"
                  primary
                  onClick={() => {
                    setModalExportarTabulacao(true);
                  }}
                />
              }
            />

            <Popup
              content="Retornar lista com todos os mailings"
              trigger={
                <Button
                  icon="numbered list"
                  primary
                  onClick={() => {
                    handleDownloadRetorno();
                  }}
                />
              }
            />
          </ListButtons>

          <Table celled color="blue">
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Ativo</Table.HeaderCell>
                <Table.HeaderCell>Nome Campanha</Table.HeaderCell>
                <Table.HeaderCell>Contatos</Table.HeaderCell>
                <Table.HeaderCell>Importou Discador</Table.HeaderCell>
                <Table.HeaderCell>Discadas</Table.HeaderCell>
                <Table.HeaderCell>Atendidas</Table.HeaderCell>
                <Table.HeaderCell>Não Atendidas</Table.HeaderCell>
                <Table.HeaderCell>Tabuladas</Table.HeaderCell>
                <Table.HeaderCell>Desligou Antes Atendimento</Table.HeaderCell>
                <Table.HeaderCell>Não Discou</Table.HeaderCell>
                <Table.HeaderCell>Ações</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {mailings &&
                mailings.map(mailing => {
                  return (
                    <Table.Row key={mailing.id} className="btn-actions">
                      <Table.Cell textAlign="center">{mailing.id}</Table.Cell>
                      <Table.Cell textAlign="center">
                        {mailing.active ? 'Ativo' : 'Inativo'}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {mailing.nome_campanha}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {mailing.quantidade_contatos}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {mailing.quantidade_importou_discador}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {mailing.quantidade_discada}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {mailing.quantidade_atendida}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {mailing.quantidade_nao_atendida}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {mailing.quantidade_tabulada}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {mailing.desligou_antes_atendimento}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {mailing.nao_discou}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <Popup
                          content="Editar Mailing"
                          trigger={
                            <Button
                              icon="pencil square"
                              color="yellow"
                              size="mini"
                              onClick={() => {
                                setContext(mailing.context);
                                setToken(mailing.token);
                                setNomeMailing(mailing.nome_campanha);
                                setIdEditModal(mailing.id);
                                setTimeout(() => {
                                  setModalEditarMailing(true);
                                }, 1);
                              }}
                            />
                          }
                        />

                        <Popup
                          content="Copiar Mailing"
                          trigger={
                            <Button
                              icon="copy"
                              size="mini"
                              onClick={() => {
                                setContext(mailing.context);
                                setToken(mailing.token);
                                setNomeMailing('');
                                setTimeout(() => {
                                  setModalAdicionarMailing(true);
                                }, 1);
                              }}
                            />
                          }
                        />

                        <Popup
                          content="Remover Mailing"
                          trigger={
                            <Button
                              icon="window close"
                              color="red"
                              size="mini"
                              onClick={() => {
                                setIdRemoveModal(mailing.id);
                                setTimeout(() => {
                                  setModalRemoverMailing(true);
                                });
                              }}
                            />
                          }
                        />

                        <Popup
                          content="Importar Contatos"
                          trigger={
                            <Button
                              icon="address book"
                              primary
                              size="mini"
                              onClick={() => {
                                setIdImportModal(mailing.id);
                                setTimeout(() => {
                                  setModalImportarContatos(true);
                                });
                              }}
                            />
                          }
                        />

                        <Popup
                          content="Pausar Todos"
                          trigger={
                            <Button
                              icon="pause"
                              color="teal"
                              size="mini"
                              disabled={mailing.active === 0}
                              onClick={() => {
                                handleRemoverTodosContatos();
                              }}
                            />
                          }
                        />

                        <Popup
                          content="Enviar Pendentes"
                          trigger={
                            <Button
                              icon="play"
                              disabled={
                                mailing.quantidade_importou_discador > 0
                              }
                              size="mini"
                              color="violet"
                              onClick={() => {
                                setIdEnviarPendentesModal(mailing.id);
                                setTimeout(() => {
                                  setModalEnviarContatosPendentes(true);
                                });
                              }}
                            />
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </Segment>
      )}

      {/* Modal adicionar Mailing */}

      <Modal
        onClose={() => setModalAdicionarMailing(false)}
        onOpen={() => setModalAdicionarMailing(true)}
        open={modalAdicionarMailing}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Adicionar Mailing</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <Form.Input
                fluid
                label="Nome do Mailing"
                placeholder="Nome do Mailing"
                value={nomeMailing}
                onChange={e => setNomeMailing(e.target.value)}
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                label="Token"
                placeholder="Token"
                value={token}
                onChange={e => setToken(e.target.value)}
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                label="Contexto"
                placeholder="Contexto"
                value={context}
                onChange={e => setContext(e.target.value)}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setModalAdicionarMailing(false)}>
            Fechar
          </Button>
          <Button
            content="Adicionar"
            labelPosition="right"
            icon="checkmark"
            onClick={() => {
              setModalAdicionarMailing(false);
              handleAdicionarMailing();
            }}
            positive
          />
        </Modal.Actions>
      </Modal>

      {/* Modal editar Mailing */}

      <Modal
        onClose={() => setModalEditarMailing(false)}
        onOpen={() => setModalEditarMailing(true)}
        open={modalEditarMailing}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Editar Mailing</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <Form.Input
                fluid
                label="Nome do Mailing"
                placeholder="Nome do Mailing"
                value={nomeMailing}
                onChange={e => setNomeMailing(e.target.value)}
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                label="Token"
                placeholder="Token"
                value={token}
                onChange={e => setToken(e.target.value)}
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                label="Contexto"
                placeholder="Contexto"
                value={context}
                onChange={e => setContext(e.target.value)}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setModalEditarMailing(false)}>
            Fechar
          </Button>
          <Button
            content="Alterar"
            color="yellow"
            labelPosition="right"
            icon="checkmark"
            onClick={() => {
              setModalEditarMailing(false);
              handleEditarMailing();
            }}
          />
        </Modal.Actions>
      </Modal>

      {/* Modal remover Mailing */}

      <Modal
        onClose={() => setModalRemoverMailing(false)}
        onOpen={() => setModalRemoverMailing(true)}
        open={modalRemoverMailing}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Remover Mailing</Modal.Header>
        <Modal.Content>
          <p>
            Remover Mailing:{' '}
            {idRemoveModal &&
              mailings.find(item => item.id === idRemoveModal).nome_campanha}
            {' ?'}
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setModalRemoverMailing(false)}>
            Fechar
          </Button>
          <Button
            content="Remover"
            color="red"
            labelPosition="right"
            icon="warning"
            onClick={() => {
              setModalRemoverMailing(false);
              handleRemoverMailing();
            }}
          />
        </Modal.Actions>
      </Modal>

      {/* Modal importar contatos */}

      <Modal
        onClose={() => setModalImportarContatos(false)}
        onOpen={() => setModalImportarContatos(true)}
        closeOnDimmerClick={false}
        open={modalImportarContatos}
      >
        <Modal.Header>Importar Contatos para Mailing</Modal.Header>
        <Modal.Content>
          <Form>
            {/* <Form.Field>
              <Form.Input
                fluid
                label="Nome do Mailing"
                placeholder="Nome do Mailing"
                value={nomeMailing}
                onChange={e => setNomeMailing(e.target.value)}
              />
            </Form.Field> */}

            <Form.Field>
              <Form.Input
                fluid
                label="Lista"
                placeholder="Lista"
                type="file"
                filename={file}
                onChange={e => {
                  setFile(e.target.files[0]);
                }}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setModalImportarContatos(false)}>
            Fechar
          </Button>
          <Button
            content="Importar"
            labelPosition="right"
            icon="angle double down"
            primary
            onClick={() => {
              setModalImportarContatos(false);
              handleImportarContatos();
            }}
          />
        </Modal.Actions>
      </Modal>

      {/* Modal Exportar Log Tabulação */}

      <Modal
        onClose={() => setModalExportarTabulacao(false)}
        onOpen={() => setModalExportarTabulacao(true)}
        closeOnDimmerClick={false}
        open={modalExportarTabulacao}
      >
        <Modal.Header>Exportar Tabulação</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <Form.Input
                fluid
                label="Data Inicial"
                placeholder="Data Inicial"
                type="date"
                value={dataInicial}
                onChange={e => setDataInicial(e.target.value)}
              />
            </Form.Field>

            <Form.Field>
              <Form.Input
                fluid
                label="Data Final"
                placeholder="Data Final"
                type="date"
                value={dataFinal}
                onChange={e => setDataFinal(e.target.value)}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={() => setModalExportarTabulacao(false)}
          >
            Fechar
          </Button>
          <Button
            content="Exportar"
            labelPosition="right"
            icon="angle double down"
            primary
            onClick={() => {
              handleDownloadTabulacoes();
              setModalExportarTabulacao(false);
            }}
          />
        </Modal.Actions>
      </Modal>

      {/* Modal enviar contatos pendentes */}

      <Modal
        onClose={() => setModalEnviarContatosPendentes(false)}
        onOpen={() => setModalEnviarContatosPendentes(true)}
        open={modalEnviarContatosPendentes}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Enviar Contatos para o Discador</Modal.Header>
        <Modal.Content>
          <p>
            Enviar contatos do Mailing:{' '}
            {idEnviarPendentesModal &&
              mailings.find(item => item.id === idEnviarPendentesModal)
                .nome_campanha}
            {' ?'}
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={() => setModalEnviarContatosPendentes(false)}
          >
            Fechar
          </Button>
          <Button
            content="Enviar"
            color="violet"
            labelPosition="right"
            icon="play"
            onClick={() => {
              setModalEnviarContatosPendentes(false);
              handleEnviarContatosPendentes();
            }}
          />
        </Modal.Actions>
      </Modal>
    </ListContainer>
  );
}
