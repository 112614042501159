/* eslint-disable jsx-a11y/media-has-caption */
import { Button, Modal } from 'semantic-ui-react';
import React, { useCallback, useEffect, useState } from 'react';
import { intervalToDuration } from 'date-fns';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import axios from 'axios';
import { useAppWebphoneContext } from '../../contexts/WebphoneContext';

import { Container } from './style';

export default function WebPhoneFront() {
  const profile = useSelector(state => state.user.profile);
  const [modalTransferOpen, setModalTransferOpen] = useState(false);
  const [origemTransfer, setOrigemTransfer] = useState(false);
  const [destinoTransfer, setDestinoTransfer] = useState(false);

  const {
    calls,
    // switchCall,
    currentCall,
    setCurrentCall,
    clickButton,
    sessionManager,

    number,
    setNumber,

    inCall,
    setInCall,

    isRinging,
    // setIsRinging,

    callType,
    callTypeEnum,
    handleStartCall,
    statusRegistro,
    classStatusSoftphone,
    agora,
    inicioChamada,
    enable,
    setEnable,
    setConfiguration,

    // isHeld,
    // hold,
    // unhold,

    // isMuted,
    // mute,
    // unmute,

    // callIsMuted,
    // callIsHeld,
  } = useAppWebphoneContext();

  const getSipAuth = useCallback(
    async ({ user, domain }) => {
      try {
        const token = '40d7771e3b2fcd1c6745f4caa1f10c75';

        const api = axios.create({
          baseURL: 'https://api.v3.cloudcom.com.br',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const {
          data: [authData],
        } = await api.get('/webphone/auth', {
          params: {
            user,
            domain,
          },
        });

        setConfiguration({
          username: user,
          domain,
          password: authData.VCH_REAL_PASSWORD,
          h1password: authData.VCH_PASSWORD,
        });

        if (!enable) {
          setEnable(!enable);
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setConfiguration]
  );

  useEffect(() => {
    getSipAuth({
      user: profile.user_basix,
      domain: profile.dominio,
    });
  }, [getSipAuth, profile.dominio, profile.user_basix]);

  return (
    <Container>
      <div className="webphones">
        {/* <button
          type="button"
          onClick={() => {
            setEnable(!enable);
          }}
        >
          Toggle
        </button> */}
        <div />
        <div id="webphone">
          <div id="status" className={classStatusSoftphone}>
            <span>{statusRegistro}</span>
          </div>
          <div id="display">
            {isRinging && (
              <div className="icon">
                <i className="ph-bell-ringing" />
              </div>
            )}

            {inCall && callType === callTypeEnum.INBOUND && (
              <div className="icon">
                <i className="ph-phone-incoming" />
              </div>
            )}

            {inCall && callType === callTypeEnum.OUTBOUND && (
              <div className="icon">
                <i className="ph-phone-outgoing" />
              </div>
            )}

            <div className="number">{number}</div>
            <div className="duration">
              {!inCall && !isRinging && <>00:00</>}

              {(inCall || isRinging) && (
                <>{`${String(
                  intervalToDuration({
                    start: agora,
                    end: inicioChamada,
                  }).minutes
                ).padStart(2, '0')}:${String(
                  intervalToDuration({
                    start: agora,
                    end: inicioChamada,
                  }).seconds
                ).padStart(2, '0')}`}</>
              )}
            </div>
          </div>

          <div>
            {calls.map(item => {
              const { state, id } = item;
              const isMutedCall = sessionManager.isMuted(item.currentCall);
              const isHeldCall = sessionManager.isHeld(item.currentCall);
              const isActiveCall = currentCall && item.id === currentCall.id;

              return (
                state !== 'ringing' && (
                  <div
                    key={id}
                    className={`calls
                      ${isActiveCall ? 'active' : ''}
                    `}
                  >
                    <span>{item.number}</span>
                    <div className="features">
                      {/* <div /> */}

                      {currentCall && item.id !== currentCall.id && (
                        <button
                          className="feature"
                          type="button"
                          onClick={() => {
                            setModalTransferOpen(true);
                            setOrigemTransfer(currentCall);
                            setDestinoTransfer(item.currentCall);

                            // sessionManager.transfer(
                            //   currentCall,
                            //   item.currentCall
                            // );
                          }}
                        >
                          <i className="ph-user-switch" />
                        </button>
                      )}

                      <button
                        className={`feature ${isMutedCall ? 'muted' : ''}`}
                        type="button"
                        onClick={() => {
                          if (!isMutedCall) {
                            sessionManager.mute(item.currentCall);
                          } else {
                            sessionManager.unmute(item.currentCall);
                          }
                        }}
                      >
                        <i className="ph-microphone-slash" />
                      </button>

                      <button
                        className={`feature ${isHeldCall ? 'held' : ''}`}
                        type="button"
                        onClick={async () => {
                          if (!isHeldCall) {
                            sessionManager.hold(item.currentCall);
                            setCurrentCall(null);
                            setInCall(false);
                            setNumber('');
                          } else {
                            sessionManager.unhold(item.currentCall);
                            setNumber(item.number);
                            setCurrentCall(item.currentCall);
                            setInCall(true);

                            // eslint-disable-next-line array-callback-return
                            calls.map(call => {
                              if (call.id !== item.id) {
                                if (!sessionManager.isHeld(item.currentCall)) {
                                  sessionManager.hold(call.currentCall);
                                }
                              }
                            });

                            setTimeout(() => {
                              sessionManager.mute(item.currentCall);

                              setTimeout(() => {
                                sessionManager.unmute(item.currentCall);
                              }, 1);
                            }, 1);
                          }
                        }}
                      >
                        {/* <i className="ph-pause" /> */}
                        {isActiveCall ? (
                          <i className="ph-pause" />
                        ) : (
                          <i className="ph-play" />
                        )}
                      </button>
                    </div>
                  </div>
                )
              );
            })}
          </div>

          <div className="keyboard">
            <button
              type="button"
              onClick={() => {
                clickButton(1);
              }}
            >
              1
            </button>
            <button
              type="button"
              onClick={() => {
                clickButton(2);
              }}
            >
              2
            </button>
            <button
              type="button"
              onClick={() => {
                clickButton(3);
              }}
            >
              3
            </button>

            <button
              type="button"
              onClick={() => {
                clickButton(4);
              }}
            >
              4
            </button>
            <button
              type="button"
              onClick={() => {
                clickButton(5);
              }}
            >
              5
            </button>
            <button
              type="button"
              onClick={() => {
                clickButton(6);
              }}
            >
              6
            </button>

            <button
              type="button"
              onClick={() => {
                clickButton(7);
              }}
            >
              7
            </button>
            <button
              type="button"
              onClick={() => {
                clickButton(8);
              }}
            >
              8
            </button>
            <button
              type="button"
              onClick={() => {
                clickButton(9);
              }}
            >
              9
            </button>

            <button
              type="button"
              onClick={() => {
                clickButton('*');
              }}
            >
              *
            </button>
            <button
              type="button"
              onClick={() => {
                clickButton(0);
              }}
            >
              0
            </button>
            <button
              type="button"
              onClick={() => {
                clickButton('#');
              }}
            >
              #
            </button>
          </div>

          {isRinging && (
            <div className="controls buttons">
              <button
                className="control idle"
                onClick={() => {
                  handleStartCall();
                }}
                type="button"
              >
                <i className="ph-phone" />
              </button>
              <button
                className="control ringing"
                onClick={() => {
                  clickButton('hangingUp');
                }}
                type="button"
              >
                <i className="ph-phone-slash" />
              </button>
            </div>
          )}

          {!isRinging && (
            <div className={`controls ${number !== '' ? 'buttons' : ''}`}>
              {inCall === false && (
                <button
                  className="control idle"
                  onClick={() => {
                    handleStartCall();
                  }}
                  type="button"
                >
                  <i className="ph-phone" />
                </button>
              )}

              {inCall === true && (
                <button
                  className="control incall"
                  onClick={() => {
                    clickButton('hangingUp');
                  }}
                  type="button"
                >
                  <i className="ph-phone-slash" />
                </button>
              )}

              {number !== '' && (
                <button
                  className="control gray"
                  onClick={() => {
                    clickButton('clear');
                  }}
                  type="button"
                >
                  <i className="ph ph-backspace" />
                </button>
              )}
            </div>
          )}
        </div>
      </div>

      <Modal size="mini" open={modalTransferOpen}>
        <Modal.Header>Transferir Chamada</Modal.Header>
        <Modal.Content>
          <p>Tem certeza que ter transferir</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={() => {
              setModalTransferOpen(false);
              setOrigemTransfer(null);
              setDestinoTransfer(null);
            }}
          >
            Não
          </Button>
          <Button
            positive
            onClick={() => {
              setModalTransferOpen(false);
              sessionManager.transfer(origemTransfer, destinoTransfer);
            }}
          >
            Sim
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
}
